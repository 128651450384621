<template>
  <v-container fluid>
    <v-row
      class="mx-auto"
      v-for="category in categories"
      :key="category.name"
    >
      <v-col cols="12">
        <h2>{{ category.name }}</h2>
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(item, _key) in category.itens"
        :key="_key"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            :id="_key"
            class="mx-auto my-auto px-2 d-flex"
            width="352px"
            height="100px"
            :color="hover ? 'grey lighten-2' : 'grey lighten-3'"
            elevation="1"
            :to="item.link"
            :disabled="item.disabled"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="2">
                <v-icon
                  class="my-auto d-flex justify-center"
                  :size="item.icon_size || 40"
                  color="primary"
                >
                  {{ item.icon }}
                </v-icon>
              </v-col>
              <v-col cols="10">
                <v-card-text class="mx-auto my-auto">
                  <div
                    class="subtitle-2"
                    style="word-break: normal"
                  >
                    {{ item.name }}
                  </div>
                  <div class="caption text--secondary">
                    {{ item.description }}
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
export default {
  mixins: [profilePermissionsMixin],
  data: () => ({
    categories: []
  }),
  mounted() {
    this.setAlgoritmosConciliacao();
    this.setAlgoritmosAneel();
  },
  methods: {
    setAlgoritmosConciliacao() {
      if (
        this.accessReleased('SIGR_COMPARA_HISTORICO') ||
        this.accessReleased('CONCILIACOES_HISTORICO') ||
        this.accessReleased('CONSOLIDADOR_QUANTIDADES_HISTORICO')
      ) {
        this.categories.push({
          name: 'Algoritmos de Conciliação',
          itens: []
        });
      }

      if (this.accessReleased('SIGR_COMPARA_HISTORICO')) {
        this.categories[0].itens.push({
          name: 'SIG-R Compara',
          link: 'sig-r-compara/historico',
          description:
            'Compara a BDGD e AIS/Laudo utilizando a metodologia SFF/ANEEL',
          icon: 'mdi-table-merge-cells'
        });
      }

      if (this.accessReleased('CONCILIACOES_HISTORICO')) {
        this.categories[0].itens.push({
          name: 'Conciliação',
          link: 'conciliacoes/historico',
          description:
            'Relaciona dados iguais ou similares de bases de dados distintas',
          icon: 'mdi-relation-many-to-many'
        });
      }

      if (this.accessReleased('CONSOLIDADOR_QUANTIDADES_HISTORICO')) {
        this.categories[0].itens.push({
          name: 'Consolidador de Quantidades',
          link: 'consolidador-quantidades/historico',
          description:
            'Calcula a aderência entre duas bases de dados utilizando atributos contábeis',
          icon: 'mdi-select-compare'
        });
      }
    },
    setAlgoritmosAneel() {
      if (this.accessReleased('SIGR_COMPARA_HISTORICO')) {
        this.categories.push({
          name: 'Algoritmos ANEEL (BulldozerR)',
          itens: []
        });
      }

      if (this.accessReleased('MUTACAO_BDGD_HISTORICO')) {
        this.categories[1].itens.push({
          name: 'Mutação da BDGD',
          link: 'mutacao-bdgd/historico',
          description: 'Analisa a evolução da BDGD entre ciclos tarifários.',
          icon: 'mdi-compare-horizontal'
        });
      }
    }
  }
};
</script>
